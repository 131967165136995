import React from "react";
import { IStepArrowProps } from "../../../../interfaces";
import ArrowDown from "../../../../assets/landing/joinTravelClub/line.svg";
import ArrowCurve from "../../../../assets/landing/joinTravelClub/curve.svg";

export const StepArrow = ({
  isCurve,
  hiddenOnDesktop = false,
  hiddenOnMobile = false,
  className,
}: IStepArrowProps): JSX.Element => {
  const arrowSrc = isCurve ? ArrowCurve : ArrowDown;
  const commonClasses = "draggable={false} width={202} height={80}";

  return (
    <img
      className={`${commonClasses} ${
        hiddenOnMobile ? `hidden ${className}` : ""
      } ${hiddenOnDesktop ? "flex md:hidden" : ""}`}
      src={arrowSrc}
      alt={isCurve ? "arrow-curve" : "arrow-down"}
    />
  );
};
