import planesDesktop from "../../../../../assets/landing/banner/planes-desktop.svg";
import wave from "../../../../../assets/landing/banner/wave.svg";
import waveEnd from "../../../../../assets/landing/banner/wave-end.svg";
import cloudLeftDesktop from "../../../../../assets/landing/banner/cloud-left-desktop.svg";
import cloudRightDesktop from "../../../../../assets/landing/banner/cloud-right-desktop.svg";
import { Trans, useTranslation } from "react-i18next";
import { AboutClubProps } from "../../../../../interfaces/pages/landing/components/banner/AboutClub";

export function DesktopAboutClub({
  section,
  title,
  description,
}: AboutClubProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="hidden md:block">
      <img src={wave} alt="wave" draggable={false} className="w-full" />
      <div
        className="flex justify-center items-center "
        style={{
          background:
            "var(--Gradients-Roaming, linear-gradient(177deg, #F2FDEB 0%, #E0F4FD 100%))",
        }}
      >
        <div className="relative flex justify-start">
          <img
            draggable={false}
            src={cloudLeftDesktop}
            alt="cloud left"
            className="absolute top-2 left-[10%] md:left-0"
          />
          <img
            src={planesDesktop}
            alt="planes desktop"
            draggable={false}
            className="lg:mr-28 relative z-10"
          />
          <img
            draggable={false}
            src={cloudRightDesktop}
            alt="cloud right"
            className="absolute top-0 right-0 "
          />
        </div>
        <div className="flex flex-col justify-start max-w-[496px] gap-4 items-start">
          <span className="bg-white rounded-full px-3 py-1 text-[#56575A] text-xs leading-4">
            {t(section)}
          </span>
          <h4 className="text-[28px] leading-[34px] font-medium">{t(title)}</h4>
          <p className="text-base font-normal whitespace-pre-line">
            <Trans i18nKey={description} />
          </p>
        </div>
      </div>
      <div className="relative h-24">
        <img
          src={waveEnd}
          alt="wave end"
          draggable={false}
          className="w-full
           absolute left-0 -top-[2px]"
        />
      </div>
    </div>
  );
}
