import { useCallback, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiClose, mdiMenu } from "@mdi/js";
import HolaflyLogo from "../../../../holafly_logo.svg";

import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";
import { MobileMenu } from "./MenuMobile";

export const NavbarMobile = (): JSX.Element => {
  const { isOpenMenuLanguage, isOpenMenuMobile, toggleMenuMobile } =
    useCustomerStore((store: ICustomerStore) => store);

  const blockScroll = useCallback(() => {
    const body = document.getElementsByTagName("body");
    if (isOpenMenuMobile || isOpenMenuLanguage) {
      body[0].style.overflow = "hidden";
    } else {
      body[0].style.overflow = "initial";
    }
  }, [isOpenMenuLanguage, isOpenMenuMobile]);

  useEffect(() => {
    blockScroll();

    return () => {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflow = "initial";
    };
  }, [isOpenMenuMobile, isOpenMenuLanguage, blockScroll]);

  return (
    <>
      <div className="gap-3 lg:gap-0 lg:hidden w-full h-[64px] flex flex-row items-center px-4 py-2 ">
        <div
          data-testid="btn_show_mobile_menu"
          onClick={() => toggleMenuMobile(!isOpenMenuMobile)}
          className="cursor-pointer rounded-xl active:bg-slate-400 relative"
        >
          <Icon
            className="transition-all duration-1000"
            path={isOpenMenuMobile ? mdiClose : mdiMenu}
            title="Web"
            size={1}
          />
        </div>

        <div className="w-full justify-center flex ">
          <a href="/">
            <img
              draggable={false}
              className="lg:hidden w-[100px] h-[32px] "
              src={HolaflyLogo}
              alt="Holafly_logo_mobile"
            />
          </a>
        </div>
        <div className="w-7" />
      </div>

      {isOpenMenuMobile && <MobileMenu />}
    </>
  );
};
