import Icon from "@mdi/react";
import { mdiClose, mdiPlusCircleOutline } from "@mdi/js";
import { t } from "i18next";

import { CountryAndStateNameCard } from "../../../components/cards/countryStateHeaderCard/CountryAndStateNameCard";
import HButton from "../../../components/common/hbutton/HButton";

import { HeaderTitleIcon } from "./HeaderTitleIcon";
import { useCustomerStore } from "../../../store/customerStore";

import { IPackagePurchaseConfirmationModal, ICustomerStore } from "../../../interfaces";

export const PackagePurchaseConfirmationModal = ({
  data,
  topup,
  onClick,
  toggleModal,
}: IPackagePurchaseConfirmationModal): JSX.Element => {
  const { language } = useCustomerStore((state: ICustomerStore) => state);

  return (
    <div className="relative bg-white p-8 md:w-[424px] w-full min-h-[490px] h-auto rounded-2xl my-auto mx-2.5">
      <HeaderTitleIcon
        onClick={toggleModal}
        classNameContain="flex flex-row-reverse justify-between font-bold text-[22px] mb-6"
        icon={mdiClose}
        isTranslate={true}
        title={"global.details.modalCheckYourPackage.titleModal"}
      />

      <CountryAndStateNameCard
        classNameContain="flex flex-col bg-white shadow-card md:w-[360px] w-full h-[106px] px-6 rounded-lg border border-neutral-300 gap-2"
        showStatusDestination={true}
        card={data}
      />

      <section className="mt-5">
        <h3
          data-testid="package-subtitle"
          className="text-neutral-gray-900 text-lg font-medium mb-5">
          {t("global.details.modalCheckYourPackage.bodyTitle")}:
        </h3>

        <div className="flex flex-row justify-between items-center p-4 gap-2 md:w-[360px] w-full h-[60px] rounded-xl border border-neutral-grey-30">
          <div className="flex gap-2 flex-row justify-center items-center">
            <Icon
              data-testid="plus-circle-outline"
              path={mdiPlusCircleOutline}
              size={0.8}
              color={"#292B2E"}
            />

            <p
              data-testid="package-name"
              className="text-neutral-gray-900 text-lg font-medium">
              {topup.name[language]}
            </p>
          </div>

          <p
            data-testid="package-price"
            className="text-neutral-gray-900 text-lg font-medium">
            {topup.price}
          </p>
        </div>
      </section>

      <section className="flex flex-row  justify-between w-[360x] h-9 text-neutral-gray-900 font-bold text-[26px] mt-5">
        <h3 data-testid="package-total">
          {t("global.details.modalCheckYourPackage.total")}:
        </h3>

        <p data-testid="package-total-price">{`${topup.price}`}</p>
      </section>

      <HButton
        type="button"
        name="button confirm your order"
        className="mt-6 bg-digital-green rounded-[14px] w-full h-12 border-none py-2 px-5 
        text-base font-medium text-neutral-charcoal hover:bg-brand-green-500 active:bg-semantic-success-400 hover:text-white"
        text={t("global.details.modalCheckYourPackage.cta")}
        onClick={onClick}
      />
    </div>
  );
};
