import React from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "./SectionTitle";
import { Step } from "./Step";
import { StepArrow } from "./StepArrow";
import { FinalCallToAction } from "./FinalCallToAction";
import Group from "../../../../assets/landing/joinTravelClub/group.svg";
import Girl from "../../../../assets/landing/joinTravelClub/girl_pc_phone.svg";
import Passport from "../../../../assets/landing/joinTravelClub/your_passport.svg";

export const HolaflyTravelClubJoin = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className="py-10 px-4 2lg:py-20 2lg:px-[316px] 2l:px-[452px] bg-yellow-200"
      id="how-to-join"
    >
      <div className="flex flex-col gap-10">
        <SectionTitle />
        <div className="flex flex-col gap-5 justify-center items-center">
          <Step
            number={0}
            text={t(
              "global.customerV2.travelClub.landing.howToJoin.step1.home"
            )}
            link="global.link.shop"
            imageSrc={Group}
            imageAlt="group"
          />
          <StepArrow isCurve={false} hiddenOnDesktop />
          <StepArrow
            isCurve={true}
            hiddenOnMobile
            className="md:flex md:relative md:right-20"
          />
          <Step
            number={1}
            text={t("global.customerV2.travelClub.landing.howToJoin.step2")}
            link="global.link.login"
            imageSrc={Girl}
            imageAlt="girl"
          />
          <StepArrow isCurve={false} hiddenOnDesktop />
          <StepArrow
            isCurve={true}
            hiddenOnMobile
            className="md:flex md:relative md:left-44"
          />
          <Step
            number={2}
            text={t("global.customerV2.travelClub.landing.howToJoin.step3")}
            link="global.link.travelClub"
            imageSrc={Passport}
            imageAlt="passport"
          />
        </div>
        <FinalCallToAction />
      </div>
    </div>
  );
};
