export const APP_ID_INTERCOM = "hhqigt3d";

const {
  REACT_APP_API_URL,
  REACT_APP_HFC_API_REQUEST_INVOICE,
  REACT_APP_HFC_API_GET_USER_ORDERS_INVOICE,
  REACT_APP_HFC_API_GET_ORDER_INVOICE,
  REACT_APP_HFC_API_GENERATE_PDF_INVOICE,
  REACT_APP_HFC_API_DELIVER_INVOICE,
  REACT_APP_HFC_API_GET_TOPUP,
  REACT_APP_HFC_API_ACTIVATE_TOPUP,
  REACT_APP_HFC_API_GET_COUNT_CARDS_CUSTOMER,
  REACT_APP_HFC_API_GET_ID_CUSTOMER,
  REACT_APP_HFC_API_GET_ICCID_CUSTOMER,
  REACT_APP_HFC_API_GET_PROFILE_USER,
  REACT_APP_HFC_API_UPDATE_PROFILE_USER,
  REACT_APP_HFC_API_LOGIN_ADMIN,
  REACT_APP_HFC_API_UPDATE_EMAIL_USER_ADMIN,
  REACT_APP_HFC_API_UPDATE_STATUS_CARD_ADMIN,
  REACT_APP_HFC_API_DELETE_USER,
  REACT_APP_HFC_API_SIGNIN_LOGIN,
  REACT_APP_HFC_API_SIGNIN_GOOGLE_LOGIN,
  REACT_APP_HFC_API_SIGNIN_APPLE_LOGIN,
  REACT_APP_HFC_API_VERIFICATION_EMAIL_LOGIN,
  REACT_APP_HFC_API_GET_USER_DETAILS_TRAVELCLUB,
  REACT_APP_HFC_API_GET_REFERRAL_LINK_TRAVELCLUB,
  REACT_APP_HFC_API_CREATE_DISCOUNT_COUPON_TRAVELCLUB,
  REACT_APP_HFC_API_KEY,
  REACT_APP_KEY_INTERCOM,
} = process.env;

const BASE_URL = REACT_APP_API_URL;

export const API_URLS = {
  URL_REQUEST_INVOICE: `${BASE_URL}${REACT_APP_HFC_API_REQUEST_INVOICE}`,
  URL_GET_USER_ORDERS_INVOICE: (customerId, page, perPage, sort) =>
    `${BASE_URL}${REACT_APP_HFC_API_GET_USER_ORDERS_INVOICE}?customerId=${customerId}&page=${page}&perPage=${perPage}&sort=${sort}`,

  URL_GET_ORDER_INVOICE: (numberOrder: string) =>
    `${BASE_URL}${REACT_APP_HFC_API_GET_ORDER_INVOICE}?name=${numberOrder}`,
  URL_GENERATE_PDF_INVOICE: `${BASE_URL}${REACT_APP_HFC_API_GENERATE_PDF_INVOICE}`,
  URL_DELIVER_INVOICE: `${BASE_URL}${REACT_APP_HFC_API_DELIVER_INVOICE}`,
  URL_GET_TOPUP: (destination: string, currentCurrency: string) =>
    `${BASE_URL}${REACT_APP_HFC_API_GET_TOPUP}/${destination}?currency=${currentCurrency}`,
  URL_ACTIVATE_TOPUP: (customerCardId: string) =>
    `${BASE_URL}${REACT_APP_HFC_API_ACTIVATE_TOPUP}/${customerCardId}`,
  URL_GET_COUNT_CARDS_CUSTOMER: (customerId: string) =>
    `${BASE_URL}${REACT_APP_HFC_API_GET_COUNT_CARDS_CUSTOMER}/${customerId}`,
  URL_GET_ID_CUSTOMER: (
    customerId: string,
    page: number | undefined,
    perPage: number | undefined,
    isIncludeProvider: boolean | undefined,
    status: string
  ) => `${BASE_URL}${REACT_APP_HFC_API_GET_ID_CUSTOMER}/${customerId}?page=${page}
  &perPage=${perPage}&includeProvider=${isIncludeProvider}&status=${status}`,
  URL_GET_ICCID_CUSTOMER: (
    iccid: string,
    isIncludeProvider: boolean | undefined
  ) =>
    `${BASE_URL}${REACT_APP_HFC_API_GET_ICCID_CUSTOMER}/${iccid}?includeProvider=${isIncludeProvider}`,
  URL_GET_PROFILE_USER: (id: string) =>
    `${BASE_URL}${REACT_APP_HFC_API_GET_PROFILE_USER}/${id}`,
  URL_UPDATE_PROFILE_USER: (id: string) =>
    `${BASE_URL}${REACT_APP_HFC_API_UPDATE_PROFILE_USER}/${id}`,
  URL_LOGIN_ADMIN: `${BASE_URL}${REACT_APP_HFC_API_LOGIN_ADMIN}`,
  URL_UPDATE_EMAIL_USER_ADMIN: `${BASE_URL}${REACT_APP_HFC_API_UPDATE_EMAIL_USER_ADMIN}`,
  URL_UPDATE_STATUS_CARD_ADMIN: `${BASE_URL}${REACT_APP_HFC_API_UPDATE_STATUS_CARD_ADMIN}`,
  URL_DELETE_USER: (customerId: string, lang: string | undefined) =>
    `${BASE_URL}${REACT_APP_HFC_API_DELETE_USER}/${customerId}${
      lang ? `?lang=${lang}` : ""
    }`,
  URL_SIGNIN_LOGIN: `${BASE_URL}${REACT_APP_HFC_API_SIGNIN_LOGIN}`,
  URL_SIGNIN_GOOGLE_LOGIN: `${BASE_URL}${REACT_APP_HFC_API_SIGNIN_GOOGLE_LOGIN}`,
  URL_SIGNIN_APPLE_LOGIN: `${BASE_URL}${REACT_APP_HFC_API_SIGNIN_APPLE_LOGIN}`,
  URL_VERIFICATION_EMAIL_LOGIN: `${BASE_URL}${REACT_APP_HFC_API_VERIFICATION_EMAIL_LOGIN}`,
  URL_GET_USER_DETAILS_TRAVELCLUB: `${BASE_URL}${REACT_APP_HFC_API_GET_USER_DETAILS_TRAVELCLUB}`,
  URL_GET_REFERRAL_LINK_TRAVELCLUB: `${BASE_URL}${REACT_APP_HFC_API_GET_REFERRAL_LINK_TRAVELCLUB}`,
  URL_CREATE_DISCOUNT_COUPON_TRAVELCLUB: `${BASE_URL}${REACT_APP_HFC_API_CREATE_DISCOUNT_COUPON_TRAVELCLUB}`,
};

export const CREDENTIALS_URL = {
  HFC_API_KEY: REACT_APP_HFC_API_KEY || "",
  SECRET_KEY_INTERCOM: REACT_APP_KEY_INTERCOM || ""
};