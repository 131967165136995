import { Trans, useTranslation } from "react-i18next";
import discountIcon from "../../../../assets/landing/discount/discount.svg";
import { useCustomerStore } from "../../../../store/customerStore";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { klavijoScript } from '../../../../constants/klavijoScripts';


export function Discount() {
  const { t } = useTranslation();
  const { language } = useCustomerStore()
  const [languageComponent, setLanguageComponent] = useState(language)

  const location = useLocation()

  useEffect(() => {
    if (language !== languageComponent) {
      setLanguageComponent(language)
      window.location.reload()
    }
  }, [location])





  return (
    <section className="flex flex-col pb-20 px-4 items-center">
      <img
        src={discountIcon}
        alt="discount"
        className="mb-4 sm:mb-3"
        draggable={false}
      />

      <h2 className="font-medium text-[22px] leading-7 sm:text-[28px] sm:leading-[34px] mb-4 sm:mb-3">
        <Trans
          i18nKey="global.customerV2.travelClub.landing.10%.title"
          components={{
            span: <span className="text-2xl font-medium text-primary" />,
          }}
        />
      </h2>

      <p className="mb-5 text-center text-base leading-[22px] max-w-[592px] sm:text-lg sm:leading-[34px]">
        {t("global.customerV2.travelClub.landing.10%.description")}
      </p>

      {
        klavijoScript[language]?.content || klavijoScript['en']?.content
      }


      <span className="text-center text-sm">
        {t("global.customerV2.travelClub.landing.10%.disclaimer")}
      </span>
    </section>
  );
}
