import { useTranslation } from "react-i18next";
import { IUnlimitedComsuptioProps } from "../../../../interfaces";

import CalendaryOutlineXL from "../../../../components/svg/calendary/CalendaryOutlineXL";

export const UnlimitedComsuptionDetails = ({
  daysRestant,
  daysTotal,
}: IUnlimitedComsuptioProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative w-[120px] h-[120px]">
      <CalendaryOutlineXL className="absolute" />
      <div className="flex flex-col justify-center items-center w-full h-full absolute top-4">
        <p className="text-[#292B2E] text-[10px] text-center">
          {t("global.customerV2.details.active.textComsuption")}
        </p>
        <p className="text-base font-bold text-neutral-charcoal">
          {daysRestant}
        </p>
        <p className="w-20 truncate text-center text-[10px] font-normal text-[#292B2E]">
          {t("global.customerV2.details.active.textComsuptionDays", {
            days: daysTotal,
          })}
        </p>
      </div>
    </div>
  );
};
