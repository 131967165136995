import HButton from "../../../../components/common/hbutton/HButton";
import { TAGS_LANDING_HERO } from "../../../../constants";
import Lines from "../../../../assets/landing/banner/lines.png";
import LinesTablet from "../../../../assets/landing/banner/lines_tablet.png";
import LinesDesktop from "../../../../assets/landing/banner/lines_desktop.png";
import ImageHero from "../../../../assets/landing/banner/image_hero.svg";
import { Trans, useTranslation } from "react-i18next";

export const BannerHero = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <section role="banner" className="flex flex-col gap-3 relative  md:mb-8 ">
      <img
        width={100}
        height={100}
        className="absolute z-0 w-full h-auto flex md:hidden"
        draggable={false}
        src={Lines}
        alt="lines-mobile"
      />

      <img
        width={100}
        height={100}
        className="absolute z-0 w-full h-auto hidden md:flex xl:hidden"
        draggable={false}
        src={LinesTablet}
        alt="lines-tablet"
      />

      <img
        width={100}
        height={0}
        className="absolute z-0 w-full h-auto md:hidden xl:flex"
        draggable={false}
        src={LinesDesktop}
        alt="lines-desktop"
      />

      <div className="flex flex-col xl:flex-row gap-3 relative z-10 xl:px-20 xl:items-center xl:justify-center 2xl:px-40 pt-[72px]">
        <div className="flex flex-col gap-5 xl:px-5 2xl:px-[70px]">
          <div className="flex flex-col gap-3 items-center justify-center xl:items-start">
            <h2
              className="text-neutral-charcoal text-center font-medium text-[34px]  
            leading-10 w-[350px] md:w-[610px] md:leading-[60px] md:text-6xl xl:text-start xl:w-[595px]"
            >
              <Trans
                i18nKey="global.customerV2.travelClub.landing.main.title"
                components={{
                  span: <span className="text-primary font-medium" />,
                }}
              />
            </h2>

            <p
              className="text-lg text-neutral-charcoal text-center font-normal px-4 md:px-0 
            md:w-[590px] xl:w-[520px] xl:text-start"
            >
              <Trans
                i18nKey="global.customerV2.travelClub.landing.main.description"
                components={{
                  span: <span className="font-bold" />,
                }}
              />
            </p>

            <div className="flex flex-row gap-4 min-w-[407px] w-auto justify-center self-center xl:justify-start xl:self-start">
              {TAGS_LANDING_HERO.map((tag) => {
                return (
                  <div
                    key={tag.title}
                    className={`flex flex-row justify-center items-center py-1 px-2 ${tag.classBackground} rounded-3xl`}
                  >
                    <tag.icon />

                    <p className="text-neutral-charcoal text-sm font-normal">
                      {t(tag.title)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <a href="#how-to-join">
            <HButton
              name="button join holafly travel club"
              text={t("global.customerV2.travelClub.landing.main.cta")}
              containerStyles="flex justify-center xl:justify-start"
              className={
                "w-[auto] text-white text-base font-medium rounded-xl px-4 py-3 bg-gradient/holafly hover:bg-gradient/holafly-hover"
              }
              type="button"
            />
          </a>
        </div>

        <img
          draggable={false}
          className="w-[310px] h-[260px] flex self-center md:h-[390px] md:w-[465px]"
          width={310}
          height={260}
          src={ImageHero}
          alt="hero-img"
        />
      </div>
    </section>
  );
};
