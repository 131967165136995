import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header";

export default function Layout({ children }): JSX.Element {
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <main className="h-full overflow-x-hidden scroll-smooth overflow-y-auto flex flex-col justify-between">
        {children}
        <Footer />
      </main>
    </div>
  );
}
