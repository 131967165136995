import Purchase from "../../../../assets/landing/benefits/purchase.webp";
import FigureOne from "../../../../assets/landing/benefits/figure_one.webp";
import Discount from "../../../../assets/landing/benefits/discount.webp";
import FigureTwo from "../../../../assets/landing/benefits/figure_two.webp";
import Coins from "../../../../assets/landing/benefits/coins.webp";
import FigureThree from "../../../../assets/landing/benefits/figure_three.webp";
import Esim from "../../../../assets/landing/benefits/eSIM.webp";
import FigureFour from "../../../../assets/landing/benefits/figure_four.webp";
import { Fragment } from "react";
import { Trans } from "react-i18next";

export const BENEFITS_LANDING_HTC_FIRST = [
  {
    id: 1,
    classBackground: "bg-blue-100",
    title: "global.customerV2.travelClub.landing.cashback",
    span: "w-[300px] md:w-1/2 2lg:w-[40%]",
    description: (t: any) => {
      return (
        <p className="text-base text-neutral-charcoal font-normal w-[280px]">
          <Trans
            i18nKey={t(
              "global.customerV2.travelClub.landing.benefits.cashback.description"
            )}
            components={{
              span: <span className="font-bold" />,
            }}
          />
        </p>
      );
    },
    images: (
      <Fragment>
        <img
          className="absolute bottom-0 right-0 rounded-br-[20px] w-[135px] md:w-[150px] 2lg:w-[180px]"
          draggable={false}
          src={FigureOne}
          alt="figure"
        />
        <img
          width={142}
          height={187}
          className="absolute rounded-br-[20px] bottom-3 right-0 w-[90px] md:w-[100px] 2lg:w-[130px]"
          draggable={false}
          src={Purchase}
          alt="purchase"
        />
      </Fragment>
    ),
  },
  {
    id: 2,
    classBackground: "bg-yellow-300",
    span: "w-[300px] md:w-1/2 2lg:w-[60%]",
    title: "global.customerV2.travelClub.landing.benefits.discounts.title",
    description: (t: any) => {
      return (
        <p className="text-base text-neutral-charcoal font-normal md:w-[270px] 2lg:w-[350px]">
          <Trans
            i18nKey={t(
              "global.customerV2.travelClub.landing.benefits.discounts.description"
            )}
            components={{
              span: <span className="font-bold" />,
            }}
          />
        </p>
      );
    },
    images: (
      <Fragment>
        <img
          className="absolute bottom-0 right-0 rounded-br-[20px] w-[105px] md:w-[130px] 2lg:w-[170px]"
          draggable={false}
          src={FigureTwo}
          alt="figure"
        />
        <img
          width={123}
          height={123}
          className="absolute rounded-br-[20px] w-[70px] bottom-1 right-3 md:bottom-2 2lg:bottom-3 md:right-0 2lg:right-7 md:w-[100px] 2lg:w-[110px]"
          draggable={false}
          src={Discount}
          alt="discount"
        />
      </Fragment>
    ),
  },
];

export const BENEFITS_LANDING_HTC_SECOND = [
  {
    id: 3,
    classBackground: "bg-green-100",
    span: "w-[300px] md:w-1/2 2lg:w-[60%]",
    title: "global.customerV2.travelClub.landing.refer&earn.title",
    description: (t: any) => {
      return (
        <p className="text-base text-neutral-charcoal font-normal md:w-[299px] 2lg:w-[450px]">
          <Trans
            i18nKey={t(
              "global.customerV2.travelClub.landing.benefits.refer&earn.description"
            )}
            components={{
              span: <span className="font-bold" />,
            }}
          />
        </p>
      );
    },
    images: (
      <Fragment>
        <img
          className="absolute bottom-0 right-0 rounded-br-[20px] w-[125px] md:w-[140px] 2lg:w-[210px]"
          draggable={false}
          src={FigureThree}
          alt="figure"
        />
        <img
          width={169}
          height={149}
          className="absolute bottom-0 right-0 rounded-br-[20px] w-[92px] md:w-[120px] 2lg:w-[160px]"
          draggable={false}
          src={Coins}
          alt="coins"
        />
      </Fragment>
    ),
  },
  {
    id: 4,
    classBackground: "bg-orange-100",
    title: "global.customerV2.travelClub.landing.freeESIMs.title",
    span: "w-[300px] md:w-1/2 2lg:w-[40%]",
    description: (t: any) => {
      return (
        <p className="text-base text-neutral-charcoal font-normal md:w-[215px] 2lg:w-[210px]">
          <Trans
            i18nKey={t(
              "global.customerV2.travelClub.landing.benefits.freeESIMs.description"
            )}
            components={{
              span: <span className="font-bold" />,
            }}
          />
        </p>
      );
    },
    images: (
      <Fragment>
        <img
          className="absolute bottom-0 right-0 rounded-br-[20px] w-[125px] md:w-[160px] 2lg:w-[190px]"
          draggable={false}
          src={FigureFour}
          alt="figure"
        />
        <img
          width={102}
          height={102}
          className="absolute bottom-3 right-3 rounded-br-[20px] w-[65px] md:w-[90px] 2lg:w-[105px]"
          draggable={false}
          src={Esim}
          alt="esim"
        />
      </Fragment>
    ),
  },
];
