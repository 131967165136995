/**
 * Expresión regular para validar direcciones de correo electrónico.
 * 
 * La expresión busca lo siguiente:
 * - [A-Za-z0-9._%+-]+: uno o más caracteres permitidos antes del símbolo '@'.
 * - @: el símbolo de arroba separa el nombre de usuario del dominio.
 * - [A-Za-z0-9.-]+: uno o más caracteres permitidos en el dominio (antes del punto).
 * - \.[A-Za-z]{2,}: un punto seguido de al menos dos letras para el dominio de nivel superior (TLD).
 *
 * @constant {RegExp} emailRegex - Expresión regular para validar direcciones de correo electrónico.
 */
export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

/**
 * Expresión regular para validar números positivos o negativos.
 * 
 * La expresión busca lo siguiente:
 * - ^-?: opcionalmente un signo negativo al inicio.
 * - \d+: uno o más dígitos en la parte entera del número.
 * - (\.\d+)?: una parte decimal opcional, con un punto seguido de uno o más dígitos.
 *
 * @constant {RegExp} isNumberPostiverOrNegative - Expresión regular para validar números positivos o negativos.
 */
export const isNumberPostiverOrNegative = /^-\d+(\.\d+)?$/;

/**
 * Expresión regular para validar el formato de fecha y hora "YYYY-MM-DD HH:MM:SS".
 * 
 * La expresión busca lo siguiente:
 * - \d{4}: exactamente cuatro dígitos para el año.
 * - -: un guión como separador.
 * - \d{2}: exactamente dos dígitos para el mes y el día.
 * - \d{2}:\d{2}:\d{2}: exactamente dos dígitos para la hora, minuto y segundo, separados por dos puntos.
 *
 * @constant {RegExp} formatYYYYMMDD - Expresión regular para validar el formato de fecha y hora "YYYY-MM-DD HH:MM:SS".
 */
export const formatYYYYMMDD = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

/**
 * Expresión regular para identificar montos en euros con dos decimales.
 * 
 * La expresión busca lo siguiente:
 * - (\d+): uno o más dígitos (la parte entera del monto).
 * - \.(\d{2}): un punto seguido de exactamente dos dígitos (los decimales).
 * - EUR: la cadena "EUR" que representa la moneda.
 * 
 * El modificador 'g' se utiliza para realizar la búsqueda de manera global en la cadena.
 *
 * @constant {RegExp} eurosDecimalRegex - Expresión regular que coincide con montos en formato de euros con dos decimales.
 */
export const eurosDecimalRegex = /(\d+)\.(\d{2}) EUR/g;

/**
 * Expresión regular para permitir solo números y letras, sin espacios.
 * 
 * La expresión busca lo siguiente:
 * - [A-Za-z0-9]: cualquier letra mayúscula (A-Z), letra minúscula (a-z) o número (0-9).
 * - +: uno o más caracteres, asegurando que la cadena no esté vacía.
 * 
 * El modificador 'g' no es necesario en este caso, ya que la expresión solo busca una coincidencia exacta para la cadena completa.
 * No se permiten espacios ni caracteres especiales.
 *
 * @constant {RegExp} alphanumericNoSpacesRegex - Expresión regular que coincide con cadenas formadas solo por números y letras, sin espacios.
 */
export const alphanumericNoSpacesRegex = /^[A-Za-z0-9]+$/;
