import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Trans, useTranslation } from "react-i18next";
import HButton from "../common/hbutton/HButton";
import HolaPoints from "../../assets/referrals/HolaPoints.svg";
import {
  useCustomNavigate,
  useDataLayer,
  useAdobeAnalytics,
} from "../../hooks";
import { useCustomerStore } from "../../store/customerStore";

export default function ReferralModal({
  onCloseModal,
}: {
  onCloseModal: () => void;
}) {
  const { t } = useTranslation();
  const { setDataLayer } = useDataLayer();
  const { setAdobeAnalytics } = useAdobeAnalytics();
  const { language } = useCustomerStore((store) => store);
  const navigate = useCustomNavigate();

  const onPressButton = () => {
    setAdobeAnalytics("referral_popup", { language });
    setDataLayer({
      event: "referral_popup",
      language,
    });

    navigate("/referralBoard?tab=referral");
    onCloseModal();
  };

  return (
    <div
      data-testid="notification-info-modal"
      className="h-full overflow-y-auto flex items-center"
    >
      <div className="my-[40px] relative bg-white mx-[30px] pt-6 pb-4 px-4 md:p-[30px] lg:p-[50px] md:w-[45rem] lg:w-[800px] rounded-xl">
        <button
          data-testid="close-modal"
          onClick={onCloseModal}
          className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
  justify-center items-center transition-all duration-300"
        >
          <Icon path={mdiClose} size={0.8} />
        </button>
        <div className="flex flex-col gap-5">
          <img
            draggable="false"
            className="h-full w-[120px] object-cover rounded-xl self-center"
            src={HolaPoints}
            alt="Learn"
          />
          <h1 className="text-neutral-charcoal text-[22px] font-medium text-center">
            {t("global.home.pop-up.referrals.title")}
          </h1>
          <div className="flex justify-center">
            <span className="text-neutral-800 text-center max-w-[392px]">
              <Trans
                i18nKey={`global.home.pop-up.referrals.description`}
                components={{
                  strong: <strong />,
                }}
              />
            </span>
          </div>

          <HButton
            type="button"
            name="button get link"
            onClick={onPressButton}
            containerStyles="flex justify-center items-center"
            className="px-5 py-3 bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold
         text-white hover:bg-gradient-to-l rounded-xl w-full"
            text={t("global.home.pop-up.referrals.button")}
          />
        </div>
      </div>
    </div>
  );
}
