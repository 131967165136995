import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiLoading, mdiTagOutline } from "@mdi/js";
import { ReferralsProfile } from "../../../../components/header/ReferralsProfile";
import { HolaPointsCounter } from "../holaPointsCounter/HolaPointsCounter";
import { useCustomerStore } from "../../../../store/customerStore";
import { postCreateDiscountCoupon } from "../../../../services";
import { ICustomerStore } from "../../../../interfaces/store";
import { useDataLayer } from "../../../../hooks/useDataLayer";
import { HolaCoinIcon } from "../../../../components/svg";
import { IRedeemHolaPointsProps } from "../../../../interfaces";
import HButton from "../../../../components/common/hbutton/HButton";

export const RedeemHolaPoints = ({
  resetALert,
  alert,
  setAlert,
  handleOpenModal,
}: IRedeemHolaPointsProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    referralUser,
    fetchReferralGetUser,
    customerId: customerIdZustand,
  } = useCustomerStore((store: ICustomerStore) => store);
  const [counter, setCounter] = useState<number>(0);

  const handleAdd = () => {
    if (!referralUser?.points_balance) {
      return;
    }

    const maxPoints = referralUser.points_balance;
    const increment = 100;

    if (counter >= maxPoints) return;

    setCounter(Math.min(counter + increment, maxPoints));
  };

  const handleLess = () => {
    if (counter <= 0) return;

    const decrement = 100;

    if (counter > 100 && counter % 100 !== 0) {
      setCounter(Math.floor(counter / 100) * 100);
    } else {
      setCounter(counter - decrement);
    }
  };
  const { setDataLayer } = useDataLayer();

  const manageCouponCreationStatusResponse = (status: number) => {
    if (status === 200) {
      setCounter(0);
      setAlert({
        message: t("global.customerV2.referrals.alerts.pointsRedeemed"),
        isError: false,
        isLoader: false,
      });
      fetchReferralGetUser("RedeemedCoupon", false);
    } else {
      setAlert({
        message: "There was an error, try again",
        isError: true,
        isLoader: false,
      });
    }
  };

  const customerId = localStorage.getItem("customerId") || customerIdZustand;

  const eventPointsRedeemed = (pointsToRedeem: number) => {
    setDataLayer({ ecommerce: null });
    setDataLayer({
      event: "custom_event",
      event_name: "aff_points_redeemed",
      ecommerce: {
        aff_id: customerId,
        aff_points_redeemed: pointsToRedeem,
        aff_discount: pointsToRedeem,
        currency: "USD",
      },
    });
  };

  const onSubmitPoints = async () => {
    setAlert({
      ...alert,
      isLoader: true,
    });

    try {
      const { status } = await postCreateDiscountCoupon(counter);
      manageCouponCreationStatusResponse(status);
      eventPointsRedeemed(counter);
    } catch (error) {
      setAlert({
        ...alert,
        isError: true,
        isLoader: false,
        message: "There was an error, try again",
      });
    }
  };

  const customText = (
    <>
      <HolaCoinIcon className="w-[24px] h-[24px]" />
      <p className="text-[#292B2E] text-[16px] lg:text-[20px]  font-bold">
        {t("global.customerV2.referrals.discountCode.RedeemHolaPoints.title", {
          referralPoint: referralUser?.points_balance || 0,
        })}
      </p>
    </>
  );

  return (
    <div
      data-testid="redeem-holapoints"
      className="h-auto justify-center bg-white p-[30px] lg:w-[466px] rounded-[8px] flex flex-col items-center gap-[24px]"
    >
      {/* <ReferralsProfile customText={customText} /> */}
      <div className="flex flex-col w-full text-center items-center gap-[8px] text-[16px] font-normal leading-[22px] text-[#292B2E]">
        <p>
          {t("global.customerV2.travelClub.benefits.description")}
        </p>
        <p>
          <Trans
            i18nKey="global.customerV2.referrals.discountCode.RedeemHolaPoints.spam"
            components={{
              b: <b />,
            }}
          />
        </p>
      </div>
      <hr className="w-full h-[1px] bg-[#E4E6EA]" />
      <HolaPointsCounter
        count={counter}
        handleAdd={handleAdd}
        handleLess={handleLess}
      />
      <div className="w-[250px]  flex justify-between items-center">
        <p className="text-[16px] font-normal text-[#56575A]">
          {t(
            "global.customerV2.referrals.discountCode.RedeemHolaPoints.textTotalDiscount"
          )}
        </p>
        <p className="text-[20px font-bold leading-[24px]">
          €{Math.ceil(counter / 100)}
        </p>
      </div>

      <HButton
        type="button"
        name="button redeem HolaPoints"
        onClick={() => {
          onSubmitPoints();
          resetALert();
        }}
        disabled={counter === 0}
        containerStyles="w-full"
        className={` ${
          counter > 0
            ? "hover:text-white hover:bg-[#31A45E] bg-[#48EC86]"
            : "bg-neutral-200"
        } flex justify-center items-center gap-[8px] px-[20px] py-[12px] rounded-[12px]`}
        icon={
          alert.isLoader ? (
            <Icon
              className="text-[14px] font-medium leading-normal animate-spin"
              path={mdiLoading}
              size={1}
              color={"black"}
            />
          ) : (
            <>
              <Icon path={mdiTagOutline} size={0.9} color={counter > 0 ? "#292B2E" : "#CCD0D7"}/>
              <p className={`${counter > 0 ? "text-neutral-charcoal" : "text-neutral-400"} text-[14px] font-medium leading-normal`}>
                {t(
                  "global.customerV2.referrals.discountCode.RedeemHolaPoints.textButton"
                )}
              </p>
            </>
          )
        }
      />

      {/* <hr className="w-full h-[1px] bg-[#E4E6EA]" />
      <p
        data-testid="open_modal_how_referral"
        onClick={handleOpenModal}
        className="underline cursor-pointer text-[16px] font-medium"
      >
        {t(
          "global.customerV2.referrals.discountCode.RedeemHolaPoints.textLink"
        )}
      </p> */}
    </div>
  );
};
