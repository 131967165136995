import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HButton from "../../../components/common/hbutton/HButton";
import { TModal } from "../../../interfaces";
import { LogoutOffset } from "../../../components/svg";
import { removeToken } from "../../../utils";
import { useCustomerStore } from "../../../store/customerStore";

export const LogoutConfirmationModal = ({
  closeModal,
}: TModal): JSX.Element => {
  const { t } = useTranslation();
  const { setShouldDisplayReferralModal } = useCustomerStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    removeToken();
    setShouldDisplayReferralModal(true);
    navigate("/login");
  };

  return (
    <div
      data-testid="logout-confirmation-modal"
      className="relative bg-white w-full md:w-[600px] mx-4 h-auto md:h-[164px] rounded-xl shadow-[0_6px_24px_rgba(0,0,0,0.1)] p-[30px] flex flex-col 
  items-center justify-center gap-7 md:gap-[26px]"
    >
      <h2 className="font-bold text-center text-base md:text-[22px] leading-5 md:leading-7 text-neutral-600">
        {t("global.account.LogoutConfirmationModal.title")}
      </h2>

      <div className="flex flex-col-reverse md:flex-row gap-2 w-full">
        <HButton
          type="button"
          name="button cancel log out"
          testID="button-cancel-logout-session-modal"
          containerStyles="w-full"
          className="py-3 px-7 border h-12 border-neutral-300 bg-white rounded-xl text-neutral-600 text-base font-medium hover:bg-neutral-200"
          text={t("global.account.LogoutConfirmationModal.btnCancel")}
          onClick={closeModal}
        />

        <HButton
          type="button"
          name="button log out"
          testID="button-logout-session-modal"
          onClick={handleLogout}
          containerStyles="w-full"
          className="py-3 px-7 h-12 flex justify-center items-center flex-row bg-magenta border-none text-white rounded-xl text-base font-medium hover:bg-magenta-900"
          icon={<LogoutOffset color="white" />}
          text={t("global.account.LogoutConfirmationModal.btnLogout")}
        />
      </div>
    </div>
  );
};
