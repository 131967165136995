import { useEffect, useState } from "react";
import { IntercomProvider } from "react-use-intercom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import CryptoJS from "crypto-js";
import { AppRouter } from "./router/AppRouter";
import { useCustomerStore } from "./store/customerStore";
import { useScreen } from "./hooks";
import VwoScriptLoader from "./scripts/VwoScriptLoader";
import {
  APP_ID_INTERCOM,
  CREDENTIALS_URL,
} from "./constants";
import "./App.css";

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    credentials: "include",
    fetch: fetch,
  }),
  cache: new InMemoryCache(),
});

const App = (): JSX.Element => {
  const [intercomLoaded, setIntercomLoaded] = useState(false);
  const {customerId} = useCustomerStore(store => store)
  const screen = useScreen();

  const userHash = CryptoJS.HmacSHA256(customerId, CREDENTIALS_URL.SECRET_KEY_INTERCOM).toString(CryptoJS.enc.Hex);

  useEffect(() => {
    let isMounted = true;

    const cleanup = () => {
      isMounted = false;
    };

    const intercomScript = document.createElement("script");
    intercomScript.src = `https://widget.intercom.io/widget/${APP_ID_INTERCOM}`;
    intercomScript.async = true;
    intercomScript.onload = () => {
      if (isMounted) {
        setIntercomLoaded(true);
      }
    };
    document.head.appendChild(intercomScript);

    return cleanup;
  }, []);

  return (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || "CLIENT_ID"}
      >
        <IntercomProvider
          autoBoot
          autoBootProps={{
            verticalPadding: 50,
            hideDefaultLauncher: screen <= 768,
            userId: customerId,
            userHash
          }}
          apiBase="https://api-iam.intercom.io"
          appId={APP_ID_INTERCOM}
        >
          <VwoScriptLoader />
          {intercomLoaded && <AppRouter />}
        </IntercomProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
};

export default App;
