export const klavijoScript = {
    en: {
        content: <div className="klaviyo-form-ReWAsL"></div>
    },
    es: {
        content: <div className="klaviyo-form-Ye3MYd"></div>
    },
    fr: {
        content: <div className="klaviyo-form-Yy4uNJ"></div>
    },
    de: {
        content: <div className="klaviyo-form-WikjtP"></div>
    },
    it: {
        content: <div className="klaviyo-form-TPVD3T"></div>
    },
    pt: {
        content: <div className="klaviyo-form-VqQKFC"></div>
    },
    ja: {
        content: <div className="klaviyo-form-RGwe6W"></div>
    },
    nl: {
        content: <div className="klaviyo-form-YzuuSn"></div>
    },
    ko: {
        content: <div className="klaviyo-form-Thc6He"></div>
    },
}
