import { ArrowEast } from "../../../../components/svg";
import { IMenuMobileElement } from "../../../../interfaces/pages/landing/components/header/menuMobileElement";

export function MenuMobileElement({
  title,
  path,
  closeModal,
}: IMenuMobileElement): JSX.Element {
  return (
    <li className="border-b flex h-[56px] items-center">
      <a
        className="flex justify-between w-full"
        href={path}
        onClick={closeModal}
        id={path}
      >
        <p className="text-[15px] font-medium">{title}</p>
        <ArrowEast />
      </a>
    </li>
  );
}
