import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { mdiTrayArrowDown } from "@mdi/js";
import { IPreviewInvoiceProps } from "./previewInvoiceInterface";
import HButton from "../../../components/common/hbutton/HButton";

export const PreviewInvoice = ({
  date,
  order_name,
  price,
  handleDownLoadPdf,
}: IPreviewInvoiceProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [refOrder, setRefOrder] = useState("");

  const handleNavigate = () => navigate(`/invoice/${order_name.split("#")[1]}`);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false)

  const downloadPdf = async (numberOrder: string) => {
    setIsLoadingPdf(true)
    await handleDownLoadPdf(numberOrder)
    setIsLoadingPdf(false)
  }

  return (
    <article
      data-testid="preview-invoice"
      className="relative hover:shadow-card flex flex-col gap-[24px] w-full lg:w-[322.67px] h-auto rounded-[12px] border p-[30px] border-[#E4E6EA] bg-[#fff]"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col w-full">
          <h3 className="text-[14px] font-normal leading-5">
            {t("global.customerV2.invoice.main.cards.title")}
          </h3>
          <h2 className="text-[20px] font-bold leading-6">{order_name}</h2>
        </div>

        <HButton
          type="button"
          name="button download pdf invoice"
          disabled={isLoadingPdf}
          onClick={() => {
            setRefOrder(order_name);
            downloadPdf(order_name);
          }}
          testID="button-download-pdf"
          className="w-[48px] h-[48px] p-[12px] rounded-[12px] border border-[#E4E6EA] hover:bg-neutral-200"
          icon={
            refOrder === order_name && isLoadingPdf ? (
              <svg
                data-testid="loading-icon"
                className="animate-spin h-5 w-5 text-neutral-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              mdiTrayArrowDown
            )
          }
        />
      </div>
      <hr className="bg-[#E4E6EA]" />
      <div className="flex w-full justify-between ">
        <p className="text-[14px] font-normal leading-5 ">
          {t("global.customerV2.invoice.main.cards.textDate")}
        </p>
        <p className="text-[16px] font-bold leading-[22px] ">{date}</p>
      </div>
      <div className="flex w-full justify-between ">
        <p className="text-[14px] font-normal leading-5 ">
          {t("global.customerV2.invoice.main.cards.textPrice")}
        </p>
        <p className="text-[16px] font-bold leading-[22px] ">
          {price?.amount || ""} {price?.currency_code || ""}
        </p>
      </div>
      <HButton
        name="button receipt details"
        type="button"
        onClick={handleNavigate}
        containerStyles="bg-white hover:bg-neutral-200 border border-neutral-300 rounded-xl h-12"
        className="py-3 px-5 text-base font-medium text-neutral-charcoal"
        text={t("global.customerV2.invoice.details.title")}
      />
    </article>
  );
};
