import { INotificationStore } from "../interfaces/store/notificationStoreInterface";
import { getLocalStorage, saveLocalStorage } from "../utils";

export const createNotificationSlice = (set): INotificationStore => ({
  showNotificationModal: false,
  setShowNotificationModal: (showNotificationModal) =>
    set({ showNotificationModal }),
  unReadIdNotifications:
    getLocalStorage("unReadIdNotifications", "Other") || [],
  setUnReadIdNotification: (id) => {
    set((state) => {
      const findUnReadNotifications = state.unReadIdNotifications.filter(
        (obj) => obj.id !== id
      );
      saveLocalStorage(
        "unReadIdNotifications",
        findUnReadNotifications,
        "Other"
      );
      return { unReadIdNotifications: findUnReadNotifications };
    });
  },
  showReferralModal: false,
  setShowReferralModal: (showReferralModal) =>
    set({
      showReferralModal,
    }),
  shouldDisplayReferralModal: true,
  setShouldDisplayReferralModal: (shouldDisplayReferralModal) =>
    set({ shouldDisplayReferralModal }),
});
