import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownFaqs } from "./Dropdown";
import { FAQs_LANDING } from "../../../../constants";
import line from "../../../../assets/landing/faqs/line.svg";

const half = Math.ceil(FAQs_LANDING.length / 2);

export function FAQs() {
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const { t } = useTranslation();

  const itemsFirstCol = FAQs_LANDING.slice(0, half).map((question) => ({
    question: t(question.question),
    response: t(question.response),
  }));

  const itemsSecondCol = FAQs_LANDING.slice(half).map((question) => ({
    question: t(question.question),
    response: t(question.response),
  }));

  const handleToggleDropdown = (index: number): void => {
    setDropdownOpen((prev) => (prev === index ? null : index));
  };

  return (
    <section
      className=" w-full h-max px-4 py-12 md:px-10 xl:px-[108px] 2xl:px-[348px] relative"
      id="faqs"
    >
      <div className="flex flex-col items-center gap-5 mb-5">
        <span className="bg-semantic-info-100 rounded-3xl px-3 py-1 text-xs">
          {t("global.customerV2.travelClub.landing.faqs.tab")}
        </span>
        <h3 className="text-[22px] leading-7 font-medium">
          {t("global.customerV2.travelClub.landing.faqs.title")}
        </h3>
        <img src={line} alt="line" draggable={false} className="md:w-[167px]" />
      </div>

      <div className="flex flex-col xl:flex-row gap-6 w-full">
        <div className="flex flex-col  gap-3 items-center w-full">
          {itemsFirstCol.map((item, index) => (
            <DropdownFaqs
              key={index}
              title={item.question}
              desc={item.response}
              toggleDropdown={() => handleToggleDropdown(index)}
              isOpen={dropdownOpen === index}
            />
          ))}
        </div>

        <div className="flex flex-col  gap-3 items-center w-full">
          {itemsSecondCol.map((item, index) => (
            <DropdownFaqs
              key={index}
              title={item.question}
              desc={item.response}
              toggleDropdown={() => handleToggleDropdown(index + half)}
              isOpen={dropdownOpen === index + half}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
