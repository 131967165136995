import React, { useEffect, useState } from "react";
import {
  BENEFITS_LANDING_HTC_FIRST,
  BENEFITS_LANDING_HTC_SECOND,
} from "./Benefit";
import { Tag } from "../../../../components/common/tag/Tag";
import Line from "../../../../assets/landing/global/line.webp";
import { useTranslation } from "react-i18next";

export const Benefits = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(1);

  useEffect(() => {
    setActiveStep(1); // Resetear a 1 cada vez que se monta el componente

    const options = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: 0.5, // Cuando el 50% del elemento esté visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveStep(parseInt(entry.target.getAttribute("data-id")!));
        }
      });
    }, options);

    const elements = document.querySelectorAll(".benefit-card");
    elements.forEach((el) => observer.observe(el));

    return () => {
      observer.disconnect(); // Desconectar el observer al desmontar
    };
  }, []);

  return (
    <section
      id="benefits"
      className="flex flex-col gap-5 justify-center px-7 py-20 2lg:px-[212px] 2lg:pt-[160px] 2lg:pb-20 2l:px-[452px]"
    >
      <div className="flex justify-center items-center">
        <Tag
          title={t("global.customerV2.travelClub.landing.benefits.tab")}
          background={"bg-blue-100"}
          color={"semantic-info-600"}
        />
      </div>

      <h3 className="text-neutral-charcoal font-medium text-[22px] md:text-[28px] text-center">
        {t("global.customerV2.travelClub.landing.benefits.title")}
      </h3>

      <img
        className="flex self-center"
        width={100}
        height={100}
        draggable={false}
        src={Line}
        alt="line"
      />

      <div className="flex flex-row md:flex-col gap-6 overflow-x-auto">
        <div className="flex flex-row justify-center gap-6">
          {BENEFITS_LANDING_HTC_FIRST.map(
            ({ id, classBackground, span, title, description, images }) => {
              return (
                <div
                  key={id}
                  data-id={id}
                  className={`${classBackground} ${span} p-5 h-[200px] rounded-[20px] relative benefit-card`}
                >
                  <h5 className="text-xl text-neutral-charcoal font-medium">
                    {t(title)}
                  </h5>

                  {description(t)}

                  {images}
                </div>
              );
            }
          )}
        </div>

        <div className="flex flex-row justify-center gap-6">
          {BENEFITS_LANDING_HTC_SECOND.map(
            ({ id, classBackground, span, title, description, images }) => {
              return (
                <div
                  key={id}
                  data-id={id}
                  className={`${classBackground} ${span} p-5 h-[200px] rounded-[20px] relative benefit-card`}
                >
                  <h5 className="text-xl text-neutral-charcoal font-medium">
                    {t(title)}
                  </h5>

                  {description(t)}

                  {images}
                </div>
              );
            }
          )}
        </div>
      </div>

      <div className="flex md:hidden flex-row gap-3 items-center justify-center">
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            role="status"
            key={index}
            className={`${
              activeStep === index + 1
                ? "w-[50px] bg-neutral-charcoal"
                : "w-[25px] bg-gray-300"
            } h-[5px] rounded-full transition-all duration-300`}
          />
        ))}
      </div>
    </section>
  );
};
