import HolaflyLogo from "../../../../holafly_logo.svg";
import { IMenuItemsNavbar } from "../../../../interfaces";
import { MenuLanguage } from "../../../../components/menus/MenuLanguage";
import { items } from "./items";
import { useTranslation } from "react-i18next";

export const NavbarDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const menuItems: IMenuItemsNavbar[] = items.map((item) => ({
    id: item.id,
    name: t(item.title),
    path: item.path,
    isCustomElement: item.isCustomElement,
  }));

  return (
    <div className="hidden lg:flex w-full justify-between lg:px-[108px] max-w-[1440px]">
      <div className="flex">
        <a href={`/`} data-testid="btn_redirect" className="flex items-center">
          <img
            draggable={false}
            className="hidden lg:block w-full h-[32px] mr-6"
            src={HolaflyLogo}
            alt="Holafly_logo_web"
          />
        </a>

        <ul className="flex flex-row">
          {menuItems.map((item) => (
            <li key={item.id} className="flex flex-col group">
              <a href={item.path}>
                <div className="py-[21px] px-5">
                  <p className="text-sm font-medium text-neutrals/600">
                    {t(item.name)}
                  </p>
                </div>
              </a>
              <div className="w-full group-hover:bg-red-500 h-[1px] " />
            </li>
          ))}
        </ul>
      </div>

      <div className="flex items-center gap-[10px]">
        <a
          href="/login"
          className="border px-5 py-3 text-[14px] leading-[14px] rounded-xl hover:bg-neutral-200 h-[40px]"
        >
          <span className="font-medium">Login</span>
        </a>
        <MenuLanguage onlyLanguage />
      </div>
    </div>
  );
};
