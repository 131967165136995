import { SVGProps } from "react";

const CashbackSymbol = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M9.501 15.974c-.83 0-1.61-.158-2.34-.473a6.062 6.062 0 0 1-1.906-1.282 6.058 6.058 0 0 1-1.282-1.905 5.839 5.839 0 0 1-.473-2.34c0-.829.157-1.609.472-2.34A6.061 6.061 0 0 1 5.255 5.73a6.058 6.058 0 0 1 1.904-1.283 5.84 5.84 0 0 1 2.34-.472c.83 0 1.61.157 2.34.472a6.06 6.06 0 0 1 1.905 1.283c.54.54.968 1.174 1.283 1.904.316.73.473 1.51.473 2.34 0 .83-.158 1.61-.472 2.34a6.061 6.061 0 0 1-1.283 1.905c-.54.54-1.175.968-1.904 1.283a5.84 5.84 0 0 1-2.34.473Zm-.001-.948c1.41 0 2.605-.489 3.584-1.468.98-.979 1.469-2.174 1.469-3.584 0-1.41-.49-2.605-1.469-3.584-.979-.98-2.173-1.469-3.584-1.469-1.41 0-2.605.49-3.584 1.469-.98.979-1.469 2.173-1.469 3.584 0 1.41.49 2.605 1.469 3.584.979.979 2.173 1.468 3.584 1.468Zm-.016-.753a.413.413 0 0 0 .302-.129.414.414 0 0 0 .13-.302v-.334c.49-.066.933-.25 1.33-.552.397-.301.596-.759.596-1.372 0-.442-.13-.833-.391-1.173-.261-.34-.77-.647-1.528-.92-.664-.227-1.105-.424-1.323-.59-.218-.166-.327-.398-.327-.696 0-.298.112-.54.335-.726.223-.186.53-.28.924-.28.234 0 .44.045.616.132.176.088.325.206.446.352.077.094.166.16.268.197a.368.368 0 0 0 .287-.005.423.423 0 0 0 .253-.249.328.328 0 0 0-.034-.323 2.21 2.21 0 0 0-.627-.595 1.702 1.702 0 0 0-.794-.269v-.334a.414.414 0 0 0-.13-.301.414.414 0 0 0-.603 0 .414.414 0 0 0-.13.301v.334c-.538.104-.951.322-1.24.656-.289.333-.433.703-.433 1.11 0 .475.144.857.431 1.146.288.289.756.55 1.405.783.672.246 1.13.467 1.376.663a.955.955 0 0 1 .369.787c0 .396-.143.684-.43.863-.285.18-.608.27-.967.27-.3 0-.571-.076-.814-.225a1.802 1.802 0 0 1-.609-.65.48.48 0 0 0-.258-.224.441.441 0 0 0-.319.003.422.422 0 0 0-.246.234.369.369 0 0 0-.001.328c.176.354.407.637.69.85.285.211.623.36 1.015.444v.365c0 .115.043.216.13.302.086.086.186.13.301.13Z"
    />
  </svg>
);
export default CashbackSymbol;
