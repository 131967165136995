import { Navigate } from "react-router-dom";
import HelpCenterPage from "../pages/help/HelpCenterPage";
import AccountPage from "../pages/account/AccountPage";
import HistoryTravelClubPage from "../pages/referralBoard/HistoryTravelClubPage";
import DetailsPage from "../pages/details/Details";
import DetailsInvoicePage from "../pages/invoice/DetailsInvoicePage";
import InvoicePage from "../pages/invoice/InvoicePage";
import MainPage from "../pages/main/Main";
import { AboutTravelClubPage } from "../pages/referralBoard/AboutTravelClubPage";
import { OnboardingTravelClubModal } from "../pages/referralBoard/components/modals/OnboardingTravelClubModal";
import { ReferralBoard } from "../pages/referralBoard/ReferralBoard";
import { LoginAdmin } from "../pages/loginAdmin/LoginAdmin";
import { PrivateRouteAdmin } from "../router/PrivateRouteAdmin";
import { HolaflyAdminRoutes } from "./HolaflyAdminRoutes";
import { LoginRoute } from "../router/LoginRoute";
import { DeleteAccountPage } from "../pages/deleteAccount/DeleteAccountPage";
import { StepInstructions } from "../pages/details/components/stepInstructions/StepInstructions";
import { InvoiceRequestModal } from "../pages/invoice/components/modals/InvoiceRequestModal";
import { PrivateRoute } from "../router/PrivateRoute";
import { HolaflyRoutes } from "./HolaflyRoutes";
import { useScreen } from "../hooks";
import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore, RouteConfig } from "../interfaces";
import LandingPage from "../pages/landing/LandingPage";

export const RoutesPrivate = () => {
  const { hasSeenOnboardingTravelClub, hasSeenInvoiceRequest } =
    useCustomerStore((store: ICustomerStore) => store);

  const screenIsSmall = useScreen() < 1024;

  const renderReferralBoard =
    screenIsSmall && !hasSeenOnboardingTravelClub ? (
      <OnboardingTravelClubModal closeModal={() => {}} />
    ) : (
      <ReferralBoard />
    );

  const renderDetailsInvoicePage =
    screenIsSmall && hasSeenInvoiceRequest ? (
      <InvoiceRequestModal refreshOrder={() => {}} closeModal={() => {}} />
    ) : (
      <DetailsInvoicePage />
    );

  const routes: RouteConfig[] = [
    { path: "/:customerId", element: <MainPage /> },
    {
      path: "/:customerId/*",
      element: <DetailsPage />,
      layout: true,
      subRoutes: [
        { path: "details/:iccid", element: <DetailsPage />, layout: true },
      ],
    },
    { path: "invoice", element: <InvoicePage />, layout: true },
    {
      path: "invoice/:orderNumber",
      element: renderDetailsInvoicePage,
      layout: true,
    },
    {
      path: "details/step-instructions/:isCall/:iccid",
      element: <StepInstructions />,
      layout: true,
    },
    { path: "referralBoard", element: renderReferralBoard, layout: true },
    {
      path: "referralBoard/history",
      element: <HistoryTravelClubPage />,
      layout: true,
    },
    {
      path: "referralBoard/about-travel-club",
      element: <AboutTravelClubPage />,
      layout: true,
    },
    { path: "account", element: <AccountPage /> },
    { path: "account/delete", element: <DeleteAccountPage />, layout: true },
  ];

  return routes;
};

export const RoutesPublic: RouteConfig[] = [
  { path: "admin", element: <LoginAdmin /> },
  {
    path: "/admin/*",
    element: (
      <PrivateRouteAdmin>
        <HolaflyAdminRoutes />
      </PrivateRouteAdmin>
    ),
  },
  { path: "login", element: <LoginRoute /> },
  {
    path: "helpCenter",
    element: <HelpCenterPage />,
  },
  {
    path: "/*",
    element: (
      <PrivateRoute>
        <HolaflyRoutes />
      </PrivateRoute>
    ),
  },
  {
    path: "/",
    element: <Navigate to={"/login"} />,
  },
  {
    path: "loyalty-program",
    element: <LandingPage />,
    layout: false,
  },
];
