export const useAdobeAnalytics = () => {
  const setAdobeAnalytics = <T extends object>(event: string, params: T) => {
    if (!window.s) return;

    window.s.tl(true, "o", event, { ...params });
  };

  return {
    setAdobeAnalytics,
  };
};
