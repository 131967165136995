import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "../../../../components/common/copyToClipboard/CopyToClipboard";
import { UniversalPagination } from "../../../../components/paginations/UniversalPagination";
import { DiscountCodeNotUsed } from "./DiscountCodeNotUsed";
import { formatDateHistoryDiscount } from "../../../../utils";
import { CuponHistory } from "../../../../interfaces";

const limitList = 4;

export const DiscountHistory = ({ referralUser }: any): JSX.Element => {
  const { t } = useTranslation();
  const [couponHistory, setCouponHistory] = useState<CuponHistory[]>([]);

  const [initialLimit, setInitialLimit] = useState<number>(0);

  useEffect(() => {
    const arrInvertHistory =
      referralUser?.cupon_history.slice().splice(initialLimit, limitList) || [];
    setCouponHistory(arrInvertHistory);
  }, [initialLimit, referralUser]);

  const getNumPagination = (items: [], limitList: number): number => {
    let numPagination = 1;
    numPagination = Math.ceil(items.length / limitList);
    return numPagination;
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <div
      data-testid="discount-history"
      className="flex flex-col lg:max-w-[466px] w-full md:h-auto md:px-[30px] h-full justify-center"
    >
      {referralUser === null || !couponHistory.length ? (
        <DiscountCodeNotUsed />
      ) : (
        <div className="flex flex-col gap-6 mt-4 md:mt-0">
          <h3
            data-testid="title-discount-history"
            className="text-neutral-charcoal text-xl font-bold text-center"
          >
            {t(
              "global.customerV2.referrals.discountCode.discountHistory.title",
              {
                currency: referralUser.redeem_value?.toFixed(0) || 0,
              }
            )}
          </h3>

          <div
            data-testid="table-history-coupon"
            className="flex flex-col gap-[5px]"
          >
            <div className="flex w-full md:flex-wrap flex-row rounded-xl py-[14px] px-3 bg-white justify-between lg:justify-start items-center overflow-x-auto">
              <div className=" md:w-[33.3%] lg:w-[130px]">
                <h5 className="text-neutral-charcoal text-sm font-bold w-20 truncate">
                  {t(
                    "global.customerV2.travelClub.benefits.previousDiscounts.dateTitle"
                  )}
                </h5>
              </div>
              <div className=" md:w-[33.3%] lg:w-[115px]">
                <h5 className="text-neutral-charcoal text-sm font-bold w-20 truncate">
                  {t(
                    "global.customerV2.referrals.discountCode.discountHistory.listTitleTable.col2.title"
                  )}
                </h5>
              </div>
              <div className="w-28 md:w-[22%]">
                <h5 className="text-neutral-charcoal text-sm font-bold md:w-[115px] lg:w-[115px] truncate">
                  {t(
                    "global.customerV2.referrals.discountCode.discountHistory.listTitleTable.col3.title"
                  )}
                </h5>
              </div>
            </div>

            {couponHistory.map(({ cupon, date, cash }, index) => {
              return (
                <div
                  key={`${index}_${cupon}`}
                  className="flex w-full md:flex-wrap flex-row rounded-xl p-3 bg-white justify-between items-center"
                >
                  <div className="md:w-[33.3%] lg:w-[130px]">
                    <p className="text-neutral-charcoal text-sm md:text-base font-normal w-20 ">
                      {formatDateHistoryDiscount(date)}
                    </p>
                  </div>
                  <div className="md:w-[33.3%] lg:w-[115px]">
                    <p className="text-neutral-charcoal text-sm md:text-base font-normal  w-20 ">
                    EUR €{Math.abs(cash)}
                    </p>
                  </div>

                  <div className="flex flex-row  items-center gap-2 md:gap-0">
                    <p className="text-neutral-charcoal text-sm md:text-base font-normal  md:w-[115px] lg:w-[115px]">
                      {cupon}
                    </p>
                    <CopyToClipboard copyText={cupon} />
                  </div>
                </div>
              );
            })}
          </div>

          {referralUser.cupon_history.length > 4 && (
            <UniversalPagination
              setInitialLimit={setInitialLimit}
              limitList={limitList}
              numPagination={getNumPagination(
                referralUser.cupon_history,
                limitList
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};
