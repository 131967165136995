import React from "react";
import HButton from "../../../../components/common/hbutton/HButton";
import { useTranslation } from "react-i18next";

export const FinalCallToAction = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-5 justify-center items-center">
      <h4 className="text-lg 2lg:text-[22px] text-neutral-charcoal font-medium text-center">
        {t("global.customerV2.travelClub.landing.howToJoin.step4")}
      </h4>
      <HButton
        name="button purchase new eSIM"
        text={t("global.customerV2.travelClub.landing.howToJoin.cta.PDP")}
        containerStyles="w-full md:w-[auto]"
        onClick={() => (window.location.href = t("global.link.shop"))}
        className="text-white text-base font-medium rounded-xl px-5 py-3 bg-gradient/holafly hover:bg-gradient/holafly-hover"
        type="button"
      />
    </div>
  );
};
