import { t } from "i18next";
import sad from "../../../../assets/referrals/sad.svg";

export const DiscountCodeNotUsed = (): JSX.Element => {
  return (
    <div
      data-testid="discountCode-notUsed"
      className="flex flex-col gap-3 justify-center items-center">
      <img
        className="w-full h-44 mt-6 md:h-auto md:w-auto md:mt-0"
        draggable={false}
        src={sad}
        alt="SAD"
      />
      <p className="text-neutral-charcoal font-bold text-xl text-center">
        {t(
          "global.travelClub.mydiscounts.codes.empty.title"
        )}
      </p>
      <p className="text-neutral-charcoal text-base font-normal">
        {t("global.travelClub.mydiscounts.codes.empty.description")}
      </p>
    </div>
  );
};
