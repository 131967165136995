import React from "react";
import { Tag } from "../../../../components/common/tag/Tag";
import Line from "../../../../assets/landing/global/line.webp";
import { useTranslation } from "react-i18next";

export const SectionTitle = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-5 justify-center items-center">
      <Tag
        title={t("global.customerV2.travelClub.landing.howToJoin.tab")}
        background="bg-yellow-300"
        color="text-yellow-600"
      />
      <h3 className="text-[22px] 2lg:text-[28px] font-medium text-neutral-charcoal">
        {t("global.customerV2.travelClub.landing.howToJoin.title")}
      </h3>
      <img
        className="flex self-center"
        width={100}
        height={100}
        draggable={false}
        src={Line}
        alt="line"
      />
    </div>
  );
};
