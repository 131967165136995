import { ICurrency } from "../interfaces";

export const languages = [
  { id: "en", label: "English" },
  { id: "es", label: "Español" },
  { id: "fr", label: "Français" },
  { id: "de", label: "Deutsch" },
  { id: "it", label: "Italiano" },
  { id: "pt", label: "Português" },
  { id: "ja", label: "日本語" },
  { id: "nl", label: "Netherlands" },
  { id: "ko", label: "한국어" },
];

export const currencys: ICurrency[] = [
  {
    label: "Euro",
    navbarLabel: "EUR (€)",
    id: "EUR - €",
    geolocation: "ES",
    currency: "EUR",
  },
  {
    label: "Dólar de los Estados Unidos (US)",
    navbarLabel: "USD ($)",
    id: "USD - $",
    geolocation: "US",
    currency: "USD",
  },
  {
    label: "Libra esterlina (GBP)",
    navbarLabel: "GBP (£)",
    id: "GBP - £",
    geolocation: "GB",
    currency: "GBP",
  },
  {
    label: "Dólar australiano (AUD)",
    navbarLabel: "AUD ($)",
    id: "AUD - $",
    geolocation: "AU",
    currency: "AUD",
  },
  {
    label: "Dólar canadiense (CAD)",
    navbarLabel: "CAD ($)",
    id: "CAD - $",
    geolocation: "CA",
    currency: "CAD",
  },
  {
    label: "Yen japonés (JPY)",
    navbarLabel: "JPY (¥)",
    id: "JPY - ¥",
    geolocation: "JP",
    currency: "JPY",
  },
];
