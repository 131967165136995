import { LanguageSelector, NavbarDesktop, NavbarMobile } from "./index";
import { ICustomerStore } from "../../../../interfaces";
import { useCustomerStore } from "../../../../store/customerStore";

export function Header() {
  const { isOpenMenuLanguage, toggleMenuLanguage } = useCustomerStore(
    (state: ICustomerStore) => state
  );

  function handleCloseLanguage() {
    toggleMenuLanguage(false);
  }
  return (
    <header className="w-full bg-white z-20">
      <nav className="flex justify-center items-center border-b border-b-neutral-300">
        <NavbarDesktop />
        <NavbarMobile />

        {isOpenMenuLanguage && (
          <LanguageSelector handleChangeVisible={handleCloseLanguage} />
        )}
      </nav>
    </header>
  );
}
