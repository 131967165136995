import { AboutClub } from "./components/banner/aboutClub/AboutClub";
import { BannerHero } from "./components/banner/BannerHero";
import { Discount } from "./components/discount/Discount";
import { Benefits } from "./components/benefits/Benefits";
import { FAQs } from "./components/faqs/FAQs";
import { HolaflyTravelClubJoin } from "./components/joinTravelClub/HolaflyTravelClubJoin";
import LayoutLanding from "./layout";

const LandingPage = (): JSX.Element => {
  return (
    <LayoutLanding>
      <BannerHero />
      <AboutClub />
      <Benefits />
      <Discount />
      <HolaflyTravelClubJoin />
      <FAQs />
    </LayoutLanding>
  );
};

export default LandingPage;
