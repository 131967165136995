import { ArrowChevronDown } from "../../../../components/svg";
import { IDropdown } from "../../../../interfaces/pages/landing/components/faqs/dropdown";

export const DropdownFaqs = ({
  title,
  desc,
  toggleDropdown,
  isOpen,
}: IDropdown): JSX.Element => {
  const iconRotate: string = isOpen ? "rotate-180" : "rotate-0";

  return (
    <div
      onClick={toggleDropdown}
      className="w-full bg-white rounded-2xl border border-neutrals/300 cursor-pointer"
    >
      <div className=" flex flex-row gap-2 items-center justify-between p-5">
        <h2 className="text-base font-normal text-neutrals/600">{title}</h2>

        <ArrowChevronDown className={`transition ${iconRotate} md:w-auto`} />
      </div>
      <div
        className={`${
          isOpen
            ? "animate-fadeIn max-h-[500px] duration-700"
            : "animate-fadeOut overflow-hidden max-h-[0px] duration-300"
        }  w-full rounded-bl-2xl rounded-br-2xl bg-neutral-200`}
      >
        <p className="text-base text-neutrals/600 font-normal p-5 flex  flex-[1_0_0]">
          {desc}
        </p>
      </div>
    </div>
  );
};
