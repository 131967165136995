import { DesktopAboutClub } from "./DesktopAboutClub";
import { MobileAboutClub } from "./MobileAboutClub";

const keys = {
  section: "global.customerV2.travelClub.menuTab.aboutClub",
  title: "global.customerV2.travelClub.landing.about.header",
  description: "global.customerV2.travelClub.landing.about.description",
};

export function AboutClub(): JSX.Element {
  return (
    <section className="z-10 relative" id="about">
      <MobileAboutClub
        section={keys.section}
        title={keys.title}
        description={keys.description}
      />
      <DesktopAboutClub
        section={keys.section}
        title={keys.title}
        description={keys.description}
      />
    </section>
  );
}
