import cloudLeft from "../../../../../assets/landing/banner/cloud-left.svg";
import cloudRight from "../../../../../assets/landing/banner/cloud-right.svg";
import plane from "../../../../../assets/landing/banner/plane.svg";
import wave from "../../../../../assets/landing/banner/wave.svg";
import waveEnd from "../../../../../assets/landing/banner/wave-end.svg";
import { Trans, useTranslation } from "react-i18next";
import { AboutClubProps } from "../../../../../interfaces/pages/landing/components/banner/AboutClub";

export function MobileAboutClub({
  section,
  title,
  description,
}: AboutClubProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="block md:hidden">
      <img src={wave} alt="wave" draggable={false} className="w-full" />
      <div
        className="px-4 py-10 flex flex-col  items-center relative gap-5"
        style={{
          background:
            "var(--Gradients-Roaming, linear-gradient(177deg, #F2FDEB 0%, #E0F4FD 100%))",
        }}
      >
        <img
          draggable={false}
          src={cloudLeft}
          alt="cloud left"
          className="absolute top-2 left-0"
        />
        <img
          draggable={false}
          src={plane}
          alt="plane"
          className="absolute md:hidden top-0 left-0"
        />

        <img
          draggable={false}
          src={cloudRight}
          alt="cloud right"
          className="absolute top-8 right-0"
        />

        <span className="bg-white rounded-full px-3 py-1 text-[#56575A]">
          {t(section)}
        </span>
        <h4 className="text-[22px] leading-[28px]  text-center font-medium ">
          {t(title)}
        </h4>
        <p className="text-center whitespace-pre-line">
          <Trans i18nKey={description} />
        </p>
      </div>
      <div className="relative">
        <img
          src={waveEnd}
          alt="wave end"
          draggable={false}
          className="w-full
           absolute left-0 -top-[1px]"
        />
      </div>
    </div>
  );
}
