import { FormEvent } from "react";
import Icon from "@mdi/react";
import { mdiInformationOutline, mdiLoading } from "@mdi/js";
import { InputLabel } from "../../../../components/input/InputLabel";
import HButton from "../../../../components/common/hbutton/HButton";
import { useForm, useScreen } from "../../../../hooks";
import { Divider } from "../../../../components/common/divider/Divider";
import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";
import { alphanumericNoSpacesRegex, dateFormatMMDDYYYY } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../../components/tooltip/Tooltip";

const formFields = {
  contactName: {
    regex: /^\S.*$/,
    message: "Name is required.",
  },
  email: {
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: "global.customerV2.invoice.requestForm.error.emailFormat",
  },
  taxId: {
    regex: alphanumericNoSpacesRegex,
    message: "global.customerV2.invoice.requestForm.error.taxID",
  },
  city: {
    regex: /^[a-zA-Z\s]+$/,
    message: "global.customerV2.invoice.requestForm.error.city",
  },
  address: {
    regex: /^[a-zA-Z0-9\s,.#-]+$/,
    message: "global.customerV2.invoice.requestForm.error.address",
  },
  postalCode: {
    regex: /^\d+$/,
    message: "global.customerV2.invoice.requestForm.error.postalCode",
  },
};

export const FormRequestInvoice = ({
  btnClose,
  sendForm,
  isLoading,
}): JSX.Element => {
  const { t } = useTranslation();
  const { orderUser: order, language } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const {
    form: { contactName, email, taxId, city, address, postalCode },
    errors,
    validate,
    onChange,
    hasAnyFieldModified,
  } = useForm(
    {
      taxId: "",
      postalCode: "",
      city: order!.billingAddress.city,
      address: order!.billingAddress.address1,
      email: order!.customerEmail,
      contactName: `${order!.customerFirstName} ${order!.customerLastName}`,
    },
    formFields
  );

  const screen = useScreen();

  const filledFieldButtonClasses = hasAnyFieldModified()
    ? "bg-gradient/holafly text-white"
    : "bg-neutral-300 text-[#56575A]";

  const disabledButtonClasses = isLoading
    ? "text-neutral-400 bg-neutral-200"
    : "text-neutral-charcoal bg-white";

  const handleSubmitRequestInvoice = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      const body = {
        language,
        order_name: order!.orderNumber,
        currency: order!.currencyCode,
        total_amount: String(order!.totalPaid),
        payment_day: dateFormatMMDDYYYY(order!.date),
        contact_name: contactName,
        email,
        tax_id: taxId,
        city,
        country: order!.billingAddress.country,
        address_line: address,
        postal_code: postalCode,
      };

      sendForm(body);
    }
  };

  return (
    <form
      onSubmit={handleSubmitRequestInvoice}
      noValidate
      className="flex flex-col gap-3 lg:gap-3.5"
    >
      <div className="px-4 py-3 bg-neutral-200 rounded-xl w-full h-16 mb-3">
        <div className="flex flex-row gap-2 items-center h-full">
          <p className="text-base font-normal text-neutral-charcoal">
            {t("global.customerV2.invoice.requestForm.personalInfo.country")}
          </p>

          <Divider className="h-[15px] bg-neutral-300 w-[1.5px]" />

          <div className="h-6 w-6 rounded-full bg-slate-500" />

          <p className="font-normal text-base text-[#56575A]">
            {order!.billingAddress.country}
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-3 lg:gap-5">
        <InputLabel
          textPlaceholder={t(
            "global.customerV2.invoice.details.personalInfoCard.textFieldName"
          )}
          type={"text"}
          name={"contactName"}
          label={t(
            "global.customerV2.invoice.details.personalInfoCard.textFieldName"
          )}
          value={contactName}
          labelStyle="text-neutral-charcoal text-xs"
          inputStyle="text-base text-neutral-charcoal"
          componentStyle="border-neutral-400"
          error={Boolean(errors.contactName)}
          errorMessage={t(errors.contactName || "")}
          onChange={(text: string) => onChange(text, "contactName")}
        />

        <InputLabel
          textPlaceholder={t(
            "global.customerV2.invoice.details.personalInfoCard.textFieldEmail"
          )}
          type={"text"}
          name={"email"}
          label={t(
            "global.customerV2.invoice.details.personalInfoCard.textFieldEmail"
          )}
          value={email}
          labelStyle="text-neutral-charcoal text-xs"
          inputStyle="text-base text-neutral-charcoal"
          componentStyle="border-neutral-400"
          error={Boolean(errors.email)}
          errorMessage={t(errors.email || "")}
          onChange={(text: string) => onChange(text, "email")}
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-3 lg:gap-5">
        <div className="relative flex flex-row items-center w-full h-full">
          <InputLabel
            textPlaceholder={t(
              "global.customerV2.invoice.requestForm.personalInfo.taxID"
            )}
            type={"text"}
            name={"taxId"}
            label={t(
              "global.customerV2.invoice.requestForm.personalInfo.taxID"
            )}
            value={taxId}
            labelStyle="text-neutral-charcoal text-xs"
            inputStyle="text-base text-neutral-charcoal"
            componentStyle="border-neutral-400 pr-3"
            error={Boolean(errors.taxId)}
            errorMessage={t(errors.taxId || "")}
            onChange={(text: string) => onChange(text, "taxId")}
          />

          <div className="absolute right-0 top-6 flex items-center">
            <Tooltip
              position={screen < 768 ? "left" : "bottom"}
              content={t("global.customerV2.invoice.requestForm.tooltip.taxID")}
            >
              <Icon path={mdiInformationOutline} size={0.7} />
            </Tooltip>
          </div>
        </div>

        <InputLabel
          textPlaceholder={t(
            "global.customerV2.invoice.requestForm.personalInfo.city"
          )}
          type={"text"}
          name={"city"}
          label={t("global.customerV2.invoice.requestForm.personalInfo.city")}
          value={city}
          labelStyle="text-neutral-charcoal text-xs"
          inputStyle="text-base text-neutral-charcoal"
          componentStyle="border-neutral-400"
          error={Boolean(errors.city)}
          errorMessage={t(errors.city || "")}
          onChange={(text: string) => onChange(text, "city")}
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-3 lg:gap-5">
        <InputLabel
          textPlaceholder={t(
            "global.customerV2.invoice.requestForm.personalInfo.address"
          )}
          type={"text"}
          name={"address"}
          label={t(
            "global.customerV2.invoice.requestForm.personalInfo.address"
          )}
          value={address}
          labelStyle="text-neutral-charcoal text-xs"
          inputStyle="text-base text-neutral-charcoal"
          componentStyle="border-neutral-400"
          error={Boolean(errors.address)}
          errorMessage={t(errors.address || "")}
          onChange={(text: string) => onChange(text, "address")}
        />

        <InputLabel
          textPlaceholder={t(
            "global.customerV2.invoice.requestForm.personalInfo.postalCode"
          )}
          type={"text"}
          name={"postalCode"}
          label={t(
            "global.customerV2.invoice.requestForm.personalInfo.postalCode"
          )}
          value={postalCode}
          labelStyle="text-neutral-charcoal text-xs"
          inputStyle="text-base text-neutral-charcoal"
          componentStyle="border-neutral-400"
          error={Boolean(errors.postalCode)}
          errorMessage={t(errors.postalCode || "")}
          onChange={(text: string) => onChange(text, "postalCode")}
        />
      </div>

      <div className="flex flex-col-reverse md:flex-row gap-5 mt-5">
        <HButton
          type="button"
          name="Close modal invoice request"
          containerStyles="w-full"
          onClick={btnClose}
          disabled={isLoading ? true : false}
          className={`${disabledButtonClasses} border border-neutral-300
          text-base text-medium rounded-xl h-12`}
          text={t("global.customerV2.button.cancel")}
        />

        <HButton
          type="submit"
          name="Send request invoice"
          disabled={!hasAnyFieldModified()}
          containerStyles="w-full"
          className={`${filledFieldButtonClasses} text-base text-medium rounded-xl h-12 flex justify-center items-center`}
          text={
            isLoading
              ? ""
              : t("global.customerV2.invoice.requestForm.button.send")
          }
          icon={
            isLoading ? (
              <Icon
                className="text-[14px] font-medium leading-normal animate-spin"
                path={mdiLoading}
                size={1}
                color={"white"}
              />
            ) : (
              <></>
            )
          }
        />
      </div>
    </form>
  );
};
