import { useState, useEffect, useRef } from "react";
import { t } from "i18next";
import { ContentModal } from "../../components/modal/ContentModal";
import { FriendDiscountModal } from "./components/modals/FriendDiscountModal";
import { AlertNotification } from "../../components/alert/AlertNotification";
import { Passport } from "./components/passport/Passport";
import { OnboardingTravelClubModal } from "./components/modals/OnboardingTravelClubModal";
import { DiscountManage } from "./components/discountManage/DiscountManage";
import { TravelClubSwitch } from "./components/travelClubSwitch/TravelClubSwitch";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import { EarnCoins } from "./components/earnCoins/EarnCoins";
import { useTimeout } from "../../hooks";
import { useCustomerStore } from "../../store/customerStore";
import { ICustomerStore } from "../../interfaces";
import { tabSwitchTravelClubOptions } from "../../constants";
import { QuestionsBanner } from "./components/questionsBanner/QuestionsBanner";

const classNameAlertNotification =
  "mt-2 fixed p-5 bottom-[75%] rounded-xl z-10 mx-2 transition-all duration-300 animate-slideDown flex self-center";

function isCashBackSelected() {
  const tab = new URLSearchParams(window.location.search).get("tab");
  return tab === "cashback" ? 1 : 0;
}

export const ReferralBoard = (): JSX.Element => {
  const { referralUser, hasSeenOnboardingTravelClub } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const [currentTab, setCurrentTab] = useState(() => isCashBackSelected());
  const [showAlert, resetALert] = useTimeout(6000);
  const [showModal, setShowModal] = useState(false);
  const [showModalOnboarding, setShowModalOnboarding] = useState(false); // Variable para controlar si mostrar el modal o no
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    isError: false,
    isLoader: false,
  });

  const elementRef = useRef<HTMLDivElement>(null);
  const earnCoinsRef = useRef<HTMLDivElement>(null);

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (hasSeenOnboardingTravelClub) {
      setShowModalOnboarding(false);
    } else {
      setShowModalOnboarding(true);
    }
  }, []);

  useEffect(function scrollToCashBack() {
    if (isCashBackSelected()) elementRef.current?.scrollIntoView();
  }, []);

  useEffect(function checkDisplayEarnHolaCoins() {
    const tab = new URLSearchParams(window.location.search).get("tab");
    if (tab === "referral") {
      setCurrentTab(1);
      earnCoinsRef?.current?.scrollIntoView();
    }
  }, []);

  return (
    <LayoutPageWithMapBackground
      title={t("global.customerV2.travelClub.title")}
      btnLabel={t("global.customerV2.buttons.buttonBackToDetail")}
    >
      <div
        data-testid="referral-board"
        ref={elementRef}
        className="flex flex-col gap-1 md:gap-10"
      >
        <Passport />

        {/* <QuestionsBanner /> */}

        <div className="flex flex-col gap-1">
          <TravelClubSwitch
            options={tabSwitchTravelClubOptions}
            currentOption={currentTab}
            setCurrentOption={setCurrentTab}
          />

          {currentTab === 0 ? (
            <div className="flex flex-col">
              <h3 className="text-[28px] font-medium text-[#292B2E] mt-10 text-center">
                {t("global.customerV2.referrals.discountCode.title")}
              </h3>

              <DiscountManage
                alert={alertMessage}
                referralUser={referralUser!}
                setAlert={setAlertMessage}
                handleOpenModal={handleOpenModal}
                resetALert={resetALert}
              />
            </div>
          ) : (
            <EarnCoins ref={earnCoinsRef} handleOpenModal={handleOpenModal} />
          )}
        </div>

        <ContentModal isModal={showModal} toggleModal={handleCloseModal}>
          <FriendDiscountModal closeModal={handleCloseModal} />
        </ContentModal>

        <ContentModal isModal={showModalOnboarding} toggleModal={() => {}}>
          <OnboardingTravelClubModal
            closeModal={() => setShowModalOnboarding(false)}
          />
        </ContentModal>

        {!alertMessage.isLoader && (
          <AlertNotification
            isVisible={showAlert}
            alertType={alertMessage.isError ? "error" : "success"}
            message={alertMessage.message}
            customClassName={`${classNameAlertNotification} ${
              !alertMessage.isError
                ? "bg-semantic-success-100"
                : "bg-semantic-error-100"
            }`}
          />
        )}
      </div>
    </LayoutPageWithMapBackground>
  );
};
